import { Transition, TransitionChild } from '@headlessui/react';
import { type CenterCardData } from '@repo/api-client';
import { type FC, Fragment, memo, useEffect, useState } from 'react';
import { GhostButton } from '../button/ghost-button';
import { CenterCard } from '../center/center-card';
import { useStoreLocatorEvents } from './store-locator-event-listener-provider';

type Props = {
  showFooter?: boolean
}

/**
 * When a markers is clicked on the map, displays a modal to show centers details
 */
const StoreLocatorMapCenterModal: FC<Props> = memo(function ({ showFooter }) {
  const events = useStoreLocatorEvents();
  const [centerData, setCenterData] = useState<CenterCardData | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const handleMarkerClicked = (centerData: CenterCardData) => {
      setCenterData(centerData);
      setIsOpen(true);
    }

    events.on('mapMarkerClicked', handleMarkerClicked);

    return () => {
      events.off(
        'mapMarkerClicked',
        handleMarkerClicked
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Transition show={isOpen} as={Fragment}>
      <div className={`absolute inset-0 z-10 flex items-center justify-center`}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className="absolute inset-0 cursor-pointer bg-black/30 backdrop-blur-md"
            onClick={closeModal}
            aria-hidden="true"
          />
        </TransitionChild>

        {/* Full-screen container to center the panel */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative z-50 m-4 w-full max-w-sm transition-all">
            {centerData && <CenterCard
              showCenterImage={true}
              center={centerData}
              className={'w-full shadow-xl'}
              expand
              showFooter={showFooter}
              secondaryBtn={
                <GhostButton onClick={closeModal} fullWidth>
                  Fermer
                </GhostButton>
              }
            />}
          </div>
        </TransitionChild>
      </div>
    </Transition>
  );
});

StoreLocatorMapCenterModal.displayName = 'StoreLocatorMapCenterModal';
export { StoreLocatorMapCenterModal };
