"use client";

import { useCallback, useEffect, useRef } from "react";
import { Map, useMap, AdvancedMarker, useApiIsLoaded } from '@vis.gl/react-google-maps';
import type { CenterCardData } from "@repo/api-client";
import Image from "next/image";
import { formatCenterName } from "@repo/utils";
import { useStoreLocator } from "@web/utils/stores/use-store-locator";
import { Circle } from "@web/components/maps/circle";
import { calculateBoundsFromRadius, FRANCE_CENTER, highlightMarker, isQuerySafe, ZOOMS_LEVELS } from "./store-locator.utils";
import { StoreLocatorMapCenterModal } from "./store-locator-map-center-modal";
import { useStoreLocatorEvents } from "./store-locator-event-listener-provider";

/**
 * The store locator google maps component
 * @returns 
 */
export const StoreLocatorMap = function () {
  const centers = useStoreLocator((state) => state.centers);
  const query = useStoreLocator((state) => state.query);
  const location = useStoreLocator(state => state.location);
  const map = useMap();
  const events = useStoreLocatorEvents();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const apiIsLoaded = useApiIsLoaded();

  // Centers the map onto the circle when a query is rich enough to draw one
  useEffect(() => {
    if (!isQuerySafe(query) || !map) return;

    map.panTo({
      lat: query.gpsLat,
      lng: query.gpsLng,
    });

    const bounds = new google.maps.LatLngBounds();
    calculateBoundsFromRadius(query).forEach(point => bounds.extend(point));
    map.fitBounds(bounds);
  }, [map, query]);

  /**
   * Triggered when a user clicks on a marker
   */
  const handleMarkerClick = useCallback((e: google.maps.MapMouseEvent, center: CenterCardData) => {
    events.emit('mapMarkerClicked', center);

    if (map) 
      map.panTo({
        lat: center.gpsLat,
        lng: center.gpsLng
      });

    if (mapContainerRef.current) {
      mapContainerRef.current.querySelectorAll('.map-marker-active').forEach(el => el.classList.remove('map-marker-active'));
      const markerEl = e.domEvent.target as HTMLElement;
      markerEl.offsetWidth; // allow to re-trigger the css animation on each click
      markerEl.classList.add('map-marker-active');
    }
  }, [events, map]);
  
  // When a marker is clicked, highlights it
  useEffect(() => {
    const highlightCenter = (center: CenterCardData) => highlightMarker(center, mapContainerRef.current, map);
    events.on('cardClicked', highlightCenter);

    return () => {
      events.off('cardClicked', highlightCenter)
    }
  }, [events, map]);

  // Sets the map center onto the the markers if focusing a location deeper than a country
  useEffect(() => {
    if (!location || location.type === "COUNTRY" || !apiIsLoaded) return;

    const bounds = new google.maps.LatLngBounds();
    for (const center of centers) {
      bounds.extend({lat: center.gpsLat, lng: center.gpsLng}); 
    }
    map?.fitBounds(bounds);
  }, [location, centers, map, apiIsLoaded]);

  return <div ref={mapContainerRef} className="map-container size-full">
    <Map
      defaultCenter={FRANCE_CENTER}
      defaultZoom={ZOOMS_LEVELS.country}
      disableDefaultUI
      mapId={"d5123aa169c99168"}
      renderingType="VECTOR"
    >
      {/* Range circle */}
      {isQuerySafe(query) && <Circle
        radius={query.distance * 1_000}
        center={{
          lat: query.gpsLat,
          lng: query.gpsLng
        }}
        fillColor={"#f7a700"}
        fillOpacity={0.1}
        strokeColor={"#f7a700"}
        strokeWeight={1}
        clickable={false}
      />}

      {/* Center markers */}
      {centers.map(center =>
        <AdvancedMarker
          key={center.id}
          position={{ lat: center.gpsLat, lng: center.gpsLng }}
          onClick={(e) => handleMarkerClick(e, center)}
        >
          <Image src="/map-marker.svg" className={`map-marker map-marker-${center.id} object-contain h-[50px]`} width={31} height={50} alt={formatCenterName(center)} />
        </AdvancedMarker>
      )}
    </Map>

    <StoreLocatorMapCenterModal />
  </div>
};

