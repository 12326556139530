"use client"

import type { FC } from "react";
import { LogoCapitalBestCompany } from "./logo-capital-best-company";
import { Question } from "@phosphor-icons/react/dist/ssr/Question";
import { Popover, PopoverContent, PopoverTrigger } from "@web/components/ui/popover";

type Props = {
  className?: string,
  textClass: string,
  centerCount: number,
  sinceYears: number
}

export const BrandLegitimityText: FC<Props> = function ({ className = '', textClass, centerCount, sinceYears }) {
  return <span className={className}>
    <Popover>
      <span className={textClass}>{centerCount}</span> agences partout en France
      - Élue{" "}
      <PopoverTrigger className={`${textClass} inline items-center gap-2 hover:underline cursor-pointer`}>
        parmi les meilleures enseignes 2022 <Question weight="bold" className="inline-block align-text-top" size={"1em"} />
      </PopoverTrigger>{" "}
      - Déjà <span className={textClass}>{sinceYears} années</span> à votre
      service

      <PopoverContent>
        <LogoCapitalBestCompany />
      </PopoverContent>
    </Popover>
  </span>;
};