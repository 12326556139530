import type { MediaEntity } from "@repo/api-client";
import { type FC } from "react";
import Image from "next/image";
import type { API } from "@web/trpc/types";

type Props = {
  media: API.Media | MediaEntity,
  priority?: boolean
  className?: string,
  height?: number,
  width?: number,
  sizes?: string,
}

export const SelfstockMedia: FC<Props> = function({media, className = '', ...props}) {
  switch (media.type) {
    case "IMAGE":
      return <Image
        src={media.url}
        alt={media.alt}
        height={props.height ?? media.height}
        width={props.width ?? media.width}
        sizes={props.sizes}
        className={className}
        priority={props.priority}
      />

    case "PHOTOSPHERE":
      return <iframe 
        loading="lazy"
        src={`/photosphere-viewer?${new URLSearchParams({
          src: media.url,
          caption: media.alt
        })}`}
        className={className}
      />

    case "EMBEDDED":
      return <iframe 
        loading="lazy"
        src={media.url}
        title={media.alt}
        className={className}
      />

    default:
      return null;
  }
};