import type { CenterCardData } from "@repo/api-client";
import type { SafeStoreLocatorQuery, StoreLocatorSearchQuery } from "./types";

/**
 * Checks that a Store Locator Query is safe for use
 * @param query
 * @returns
 */
export function isQuerySafe(
  query: StoreLocatorSearchQuery,
): query is SafeStoreLocatorQuery {
  return (
    typeof query.gpsLat === "number" &&
    typeof query.gpsLng === "number" &&
    typeof query.distance === "number"
  );
}

/**
 * Calculate map bounds from a given coords and radius
 * @param query
 * @returns
 */
export function calculateBoundsFromRadius(query: SafeStoreLocatorQuery) {
  const earthRadius = 6371000; // Radius of the Earth in meters
  const latRadians = (query.gpsLat * Math.PI) / 180; // Convert latitude to radians

  const dist = query.distance * 1_000;

  // Latitude bounds (north/south)
  const latDelta = (dist / earthRadius) * (180 / Math.PI);

  const minLat = query.gpsLat - latDelta;
  const maxLat = query.gpsLat + latDelta;

  // Longitude bounds (east/west), adjusting for latitude
  const lonDelta =
    (dist / (earthRadius * Math.cos(latRadians))) * (180 / Math.PI);

  const minLon = query.gpsLng - lonDelta;
  const maxLon = query.gpsLng + lonDelta;

  return [
    { lat: minLat, lng: minLon },
    { lat: maxLat, lng: maxLon },
  ] satisfies google.maps.LatLngLiteral[];
}

export function highlightMarker(
  center: CenterCardData,
  mapContainerEl?: HTMLElement | null,
  map?: google.maps.Map | null,
) {
  // Disable previous higlighted markers
  mapContainerEl
    ?.querySelectorAll(".map-marker-active")
    .forEach((activeMarkerEl) =>
      activeMarkerEl.classList.remove("map-marker-active"),
    );

  // Finds and highlight the center marker
  const markerEl = mapContainerEl?.querySelector(
    `.map-marker-${center.id}`,
  ) as HTMLElement;
  if (markerEl) {
    markerEl.offsetWidth; // allow to re-trigger the css animation on each click
    markerEl.classList.add("map-marker-active");
  }

  // Set the map center onto the center coords
  if (map)
    map.panTo({
      lat: center.gpsLat,
      lng: center.gpsLng,
    });
}

export const FRANCE_CENTER: google.maps.LatLngLiteral = {
  lat: 47.07214310134873,
  lng: 1.6082353437499908,
} as const;

export const ZOOMS_LEVELS = {
  country: 6,
  city: 12,
} as const;
